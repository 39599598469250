import React from 'react'
import Me from '../component/Me'


function About() {
  return (
    <div className='centered text-4xl h-screen'>
      <Me/>
    </div>
  )
}

export default About
