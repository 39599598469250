


function Card({image}) {


  return (
    <div  className="hover:shadow-lg" >
         <img src={image}  alt="ordi"/>
    </div>
  )
}

export default Card




