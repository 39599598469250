import React from 'react'
import '../index.css'
import Hero from '../component/Hero'

function Home() {
  return (
    <div className='centered text-4xl h-screen'>
      <Hero/>
    </div>
  )
}

export default Home
