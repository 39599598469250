import React from 'react'
import ContactInfo from '../component/ContactInfo'

function Contact() {
  return (
    <div>
      <ContactInfo/>
   </div>
  )
}

export default Contact
